.table tbody td {
  padding: 0.2em 0.5em;
  max-width: 200px;
}

:global(.table tbody tr:hover) {
  background-color: rgb(239, 231, 221);
}

.title {
  margin: 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  max-width: 50vw;
}
.titleContainer > div {
  max-width: 75%;
}

.description {
  margin: 0;
  color: rgb(92, 92, 92);
  font-size: 0.7em;
  white-space: break-spaces;
  overflow-wrap: break-word;
}

.mobileDate {
  margin: 0;
  color: rgb(55, 55, 55);
  font-size: 0.8em;
}

.dateCell {
  display: flex;
  justify-content: space-around;
}

.cardButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3em;
}

.fastDateBtnContainer {
  display: flex;
  flex-direction: column;
}
