.listGroupContainer {
  display: flex;
  justify-content: space-between;
}

.listGroupButtonAndTitle {
  display: flex;
  align-items: baseline;
  flex: 1;
}

.listGroupTitle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-right: 1em;
}
