.repeatContainer {
  padding: 0.5em 0.5em;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 0.2em;
}

.repeatHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.datetimeContainer {
  display: flex;
}

.startDatetimeContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}
.endDatetimeContainer {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.dateContainer {
  display: flex;
  flex: 1;
}

.dateLeftButton {
  margin-right: 0.2rem;
  margin-bottom: 0.25rem;
  min-width: 2.5rem;
}

.dateRightButton {
  margin-left: 0.2rem;
  margin-bottom: 0.25rem;
  min-width: 2.5rem;
}

:global(.input-group-text) {
  padding: 0 0.3rem;
}

@media only screen and (max-width: 400px) {
  .datetimeContainer {
    flex-direction: column;
  }
}

@media only screen and (min-width: 400px) {
  .startDatetimeContainer {
    margin-right: 0.1rem;
  }
  .endDatetimeContainer {
    margin-left: 0.1rem;
  }
  .dateLeftButton,
  .dateRightButton {
    display: none !important;
  }
}
